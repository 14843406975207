import { Injectable } from '@angular/core';
import { s3ClientUrl } from '@awread/kernel/tools';
import { Store, StoreConfig } from '@datorama/akita';

export interface SettingState {
  storage: string;
  version: string;
}

export function createInitialState(): SettingState {
  return {
    storage: s3ClientUrl,
    version: '1.0.0',
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'setting', resettable: true, idKey: 'settingId' })
export class SettingStore extends Store<SettingState> {
  constructor() {
    super(createInitialState());
  }
}
