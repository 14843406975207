import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, BaseRouteReuseStrategy, RouteReuseStrategy, Route } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ReuseStrategyService implements RouteReuseStrategy {
  // LINK: https://itnext.io/cache-components-with-angular-routereusestrategy-3e4c8b174d5f
  private handlers: Map<Route, DetachedRouteHandle> = new Map();

  constructor() { }

  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data['reuseComponent'];
  }

  public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    if (!route.routeConfig) return;
    this.handlers.set(route.routeConfig, handle);
  }

  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!route.routeConfig && !!this.handlers.get(route.routeConfig);
  }

  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    if (!route.routeConfig || !this.handlers.has(route.routeConfig)) return null;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.handlers.get(route.routeConfig)!;
  }

  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }
}
