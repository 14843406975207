import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { environment } from '@awread/global/environments';
import { SnackbarService } from '@awread/global/packages';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';
// BUG 1: HASH MISMATCH
// https://stackoverflow.com/questions/70411832/angular-service-worker-manifest-webmanifest-hash-mismatch/70411899#70411899
// https://stackoverflow.com/questions/62711670/angular-9-pwa-hash-mismatch-cachebustedfetchfromnetwork
// BUG 2: service worker not update new image on new version
// add "/global-assets/**" to ngsw-config.json in assetGroups to  update new image on change and track it with hash table

// NOTE: we add installable here
// https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps/Add_to_home_screen
// https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps/Installable_PWAs
// https://firt.dev/ios-15.4b
@Injectable({ providedIn: 'root' })
export class ServiceWorkerService {
  isFailed = false;

  constructor(private updates: SwUpdate, private appRef: ApplicationRef, private snackbarService: SnackbarService) { }

  start() {
    if ('serviceWorker' in navigator) {
      try {
        this.askToDistruct();
        this.checkForUpdate();
        this.activateUpdate();

        // console.log('service worker ready!');
      } catch (error) {
        console.warn('service worker cannot start...', error);
      }
    }
  }

  askToDistruct() {
    this.updates.unrecoverable.subscribe((event) => {
      console.warn('unrecoverable event:', event);
      const ref = this.snackbarService.openConfirm('Bản cập nhật mới đã ra mắt. Không thể tự cập nhật, bạn có muốn tải lại trang?');
      ref.afterClosed$.subscribe(async (result) => {
        await this.distructNow();
      });
    });
  }

  async distructNow() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(registrations => {
        for (const registration of registrations) {
          // keep only serviceWorker which scope is /disable-service-worker/, The purpose is to make serviceWorker useless
          if (registration.scope.includes('/disable-service-worker/') === false) {
            registration.unregister()
          }
        }
      });
      // clear cache of service worker
      caches.keys().then(keyList => {
        return Promise.all(
          keyList.map(key => {
            return caches.delete(key);
          }),
        );
      });
    }
  }

  activateUpdate() {
    this.updates.versionUpdates.subscribe(async (event) => {
      console.warn('service worker active event?', event);
      switch (event.type) {
        case 'VERSION_DETECTED':
          this.snackbarService.showWarning('Đang cập nhật...');
          this.checkNewVersion();
          break;
        case 'VERSION_INSTALLATION_FAILED':
          this.snackbarService.showWarning(this.isFailed === false ? 'Cập nhật suýt thành công!' : 'Sên thất bại nên người dùng vui lòng tự tải lại trang...');
          await this.clearCache();
          this.snackbarService.showWarning('Đang xóa worker...');
          await this.distructNow();
          this.isFailed = true;
          break;
        case 'VERSION_READY':
          this.snackbarService.showSuccess('Cập nhật xong, sẵn sàng trong lần khởi động sau...');
          break;
        default:
          break;
      }
    });
  }

  private async clearCache() {
    // clear cache of service worker
    caches.keys().then(keyList => {
      return Promise.all(
        keyList.map(key => {
          return caches.delete(key);
        }),
      );
    });
  }

  checkNewVersion() {
    const ref = this.snackbarService.openConfirm('Bản cập nhật mới đã ra mắt. Bạn có muốn cập nhật?');
    ref.afterClosed$.subscribe(async (result) => {
      if (result) {
        const updatedStatus = await this.updates.activateUpdate();
        if (updatedStatus) {
          this.snackbarService.showSuccess('Cập nhật thành công! Tiến hành xóa cache...');
          await this.clearCache();
          this.snackbarService.showSuccess('Tự động tải lại trang...');
          setTimeout(() => {
            document.location.reload();
          }, 1000);
        } else {
          this.snackbarService.showWarning('Cập nhật không thành công!');
        }
      }
    });
  }

  checkForUpdate() {
    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));
    const everySixHours$ = interval(6 * 60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    if (environment.production)
      everySixHoursOnceAppIsStable$.subscribe(() => {
        console.log('cheking update follow the plan');
        this.updates.checkForUpdate();
      });
  }
}
