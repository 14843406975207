<div *ngIf="showToast && toastMessage" [ngClass]="{'bottom-5 right-5': toastConfig.position === 'bottom-right'}" class="fixed z-1060">
  <div class="w-80 bg-white shadow rounded">
    <div *ngIf="toastConfig.isBtnClose" (click)="closeToast()" class="absolute top-2 right-2">
      <fa-icon [icon]="icons.faXmark"></fa-icon>
    </div>
    <div class="py-4 px-6 flex items-center">
      <div [ngSwitch]="toastConfig.typeToast" class="mr-2">
        <p *ngSwitchCase="'success'">
          <fa-icon class="text-xl text-green-500" [icon]="icons.faCheckCircle"></fa-icon>
        </p>
        <p *ngSwitchCase="'warning'">
          <fa-icon class="text-xl text-yellow-500" [icon]="icons.faAnchorCircleExclamation"></fa-icon>
        </p>
        <p *ngSwitchDefault>
          <fa-icon class="text-xl text-red-500" [icon]="icons.faCircleXmark"></fa-icon>
        </p>
      </div>
      <p class="">{{ toastMessage }}</p>
    </div>
  </div>
</div>