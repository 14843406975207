// Angular
import { InjectionToken, TransferState, makeStateKey } from '@angular/core';
// Apollo
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { authLink, createPersitedLink, createSocketLink, emptyLink, errorLink } from './apollo.link.js';
import { environment } from '@awread/global/environments';
import { UniversalService } from '@awread/kernel/services';
import hashes from '../../../../../persisted-query-ids/client.json';

const APOLLO_CACHE = new InjectionToken<InMemoryCache>('apollo-cache');
const STATE_KEY = makeStateKey<any>('apollo.state');

export const apolloProvider = [
  {
    provide: APOLLO_CACHE,
    useValue: new InMemoryCache(),
  },
  {
    provide: APOLLO_OPTIONS,
    useFactory(httpLink: HttpLink, cache: InMemoryCache, universalService: UniversalService, transferState: TransferState) {
      const isBrowserAndHaveCache = transferState.hasKey<any>(STATE_KEY);
      if (isBrowserAndHaveCache) {
        const state = transferState.get<any>(STATE_KEY, null);
        // console.log(`${STATE_KEY} | STATE_KEY:`, isBrowserAndHaveCache, state);
        cache.restore(state);
      } else {
        // console.log(`${STATE_KEY} | STATE_KEY: no cache here, start doing this on server`, transferState);
        // debugger;
        transferState.onSerialize(STATE_KEY, () => {
          // console.log('cache.extract();', cache.extract());
          return cache.extract();
        });
        // Reset cache after extraction to avoid sharing between requests
        cache.reset();
      }

      // console.warn('do we create persist link?', environment.production)

      const myHttpLink = httpLink.create({
        includeExtensions: true,
        uri: environment.graphqlEndpoint,
      });

      return {
        link: ApolloLink.from([
          errorLink,
          authLink,
          // createPersitedLink(hashes),
          // PERSIST QUERY 2
          environment.production ? createPersitedLink(hashes) : emptyLink,
          universalService.isBrowser() ? createSocketLink(myHttpLink) : myHttpLink,
        ]),
        cache,
        // ssrForceFetchDelay: 100,
        ssrMode: universalService.isServer(),
      };
    },
    deps: [HttpLink, APOLLO_CACHE, UniversalService, TransferState],
  },
];
