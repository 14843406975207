import { Injectable } from '@angular/core';
import { environment } from '@awread/global/environments';

@Injectable({ providedIn: 'root' })
export class DevtoolService {
  welcomeText = `Chào mừng bạn đến với Awread, cảm ơn sự quan tâm của bạn, dành thời gian để tìm hiểu Awread là sự ủng hộ rất lớn với chúng tôi. Nếu bạn cảm thấy muốn đóng góp ý kiến, góp ý về mã nguồn hoặc bạn cần sự giúp đỡ hay có câu hỏi gì, hãy cứ tự nhiên liên hệ với chúng tôi tại: https://www.facebook.com/awread.vn/ hoặc email: admin@awread.vn

Việc tìm hiểu, chỉnh sửa mã nguồn của Awread là vi phạm pháp luật. Việc xem mã nguồn với ý định đánh cắp cấu thành tội trộm cắp tài sản theo bộ luật hình sự 2015 và sẽ bị khởi tố, bắt tạm giam, và đi tù nếu cấu thành đủ vi phạm sau:

Điều 173. Tội trộm cắp tài sản

1. Người nào trộm cắp tài sản của người khác trị giá từ 2.000.000 đồng đến dưới 50.000.000 đồng hoặc dưới 2.000.000 đồng nhưng thuộc một trong các trường hợp sau đây, thì bị phạt cải tạo không giam giữ đến 03 năm hoặc phạt tù từ 06 tháng đến 03 năm:

a) Đã bị xử phạt vi phạm hành chính về hành vi chiếm đoạt tài sản mà còn vi phạm;

b) Đã bị kết án về tội này hoặc về một trong các tội quy định tại các điều 168, 169, 170, 171, 172, 174, 175 và 290 của Bộ luật này, chưa được xóa án tích mà còn vi phạm;

c) Gây ảnh hưởng xấu đến an ninh, trật tự, an toàn xã hội;

d) Tài sản là phương tiện kiếm sống chính của người bị hại và gia đình họ; tài sản là kỷ vật, di vật, đồ thờ cúng có giá trị đặc biệt về mặt tinh thần đối với người bị hại.

2. Phạm tội thuộc một trong các trường hợp sau đây, thì bị phạt tù từ 02 năm đến 07 năm:

a) Có tổ chức;

b) Có tính chất chuyên nghiệp;

c) Chiếm đoạt tài sản trị giá từ 50.000.000 đồng đến dưới 200.000.000 đồng;

d) Dùng thủ đoạn xảo quyệt, nguy hiểm;

đ) Hành hung để tẩu thoát;

e) Trộm cắp tài sản trị giá từ 2.000.000 đồng đến dưới 50.000.000 đồng nhưng thuộc một trong các trường hợp quy định tại các điểm a, b, c và d khoản 1 Điều này;

g) Tái phạm nguy hiểm.

3. Phạm tội thuộc một trong các trường hợp sau đây, thì bị phạt tù từ 07 năm đến 15 năm:

a) Chiếm đoạt tài sản trị giá từ 200.000.000 đồng đến dưới 500.000.000 đồng;

b) Trộm cắp tài sản trị giá từ 50.000.000 đồng đến dưới 200.000.000 đồng nhưng thuộc một trong các trường hợp quy định tại các điểm a, b, c và d khoản 1 Điều này;

c) Lợi dụng thiên tai, dịch bệnh.

4. Phạm tội thuộc một trong các trường hợp sau đây, thì bị phạt tù từ 12 năm đến 20 năm:

a) Chiếm đoạt tài sản trị giá 500.000.000 đồng trở lên;

b) Trộm cắp tài sản trị giá từ 200.000.000 đồng đến dưới 500.000.000 đồng nhưng thuộc một trong các trường hợp quy định tại các điểm a, b, c và d khoản 1 Điều này;

c) Lợi dụng hoàn cảnh chiến tranh, tình trạng khẩn cấp.

5. Người phạm tội còn có thể bị phạt tiền từ 5.000.000 đồng đến 50.000.000 đồng."

      `;


  start() {
    if (environment.production) {
      console.warn(this.welcomeText);
    }
  }
  // showMatrix = true;
  // isSafari = (navigator.vendor.match(/apple/i) || '').length > 0;
  // isFirefox = navigator.userAgent.toLowerCase().includes('firefox');
  // devtools = () => { };
  // start() {
  //   if (environment.production) {
  //     console.warn(this.welcomeText);
  //   }
  //   this.watchDevTool();
  // }

  // watchDevTool() {
  //   // Detect Fire Bug
  //   if (window.console && (window.console as any)?.firebug) {
  //     this.DevToolsOpened('firebug');
  //   }

  //   // Detect Key Shortcuts
  //   // https://stackoverflow.com/a/65135979/9498503 (hlorand)
  //   window.addEventListener('keydown', (e) => {
  //     if (
  //       // CMD + Alt + I (Chrome, Firefox, Safari)
  //       (e.metaKey == true && e.altKey == true && e.key === 'I') ||
  //       // CMD + Alt + J (Chrome)
  //       (e.metaKey == true && e.altKey == true && e.key === 'J') ||
  //       // CMD + Alt + C (Chrome)
  //       (e.metaKey == true && e.altKey == true && e.key === 'C') ||
  //       // CMD + Shift + C (Chrome)
  //       (e.metaKey == true && e.shiftKey == true && e.key === 'C') ||
  //       // Ctrl + Shift + I (Chrome, Firefox, Safari, Edge)
  //       (e.ctrlKey == true && e.shiftKey == true && e.key === 'I') ||
  //       // Ctrl + Shift + J (Chrome, Edge)
  //       (e.ctrlKey == true && e.shiftKey == true && e.key === 'J') ||
  //       // Ctrl + Shift + C (Chrome, Edge)
  //       (e.ctrlKey == true && e.shiftKey == true && e.key === 'C') ||
  //       // F12 (Chome, Firefox, Edge)
  //       e.key === 'F12' ||
  //       // CMD + Alt + U, Ctrl + U (View source: Chrome, Firefox, Safari, Edge)
  //       (e.metaKey == true && e.altKey == true && e.key === 'U') ||
  //       (e.ctrlKey == true && e.key === 'U')
  //     ) {
  //       this.DevToolsOpened('keydown');
  //     } else {
  //       console.log('e.key', e.ctrlKey, e.shiftKey, e.altKey, e.key);
  //     }
  //   });
  // }

  // // https://stackoverflow.com/a/65878084/5748537
  // safariCheck() {
  //   const el = new Image();
  //   let consoleIsOpen = false;
  //   let consoleOpened = false;

  //   Object.defineProperty(el, 'id', {
  //     get: () => {
  //       consoleIsOpen = true;
  //     },
  //   });

  //   const verify = () => {
  //     console.dir(el);
  //     if (consoleIsOpen === false && consoleOpened === true) {
  //       // console closed
  //       window.dispatchEvent(new Event('devtools-opened'));
  //       consoleOpened = false;
  //     } else if (consoleIsOpen === true && consoleOpened === false) {
  //       // console opened
  //       window.dispatchEvent(new Event('devtools-closed'));
  //       consoleOpened = true;
  //     }
  //     consoleIsOpen = false;
  //     setTimeout(verify, 1000);
  //     // this.DevToolsOpened();
  //   };

  //   verify();
  // }

  // // // DevTools Opened Script

  // DevToolsOpened(prefix = '') {
  //   alert(prefix + this.welcomeText);
  //   console.error(prefix + this.welcomeText);
  // }
}
