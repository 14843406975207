import { APP_ID, APP_INITIALIZER, ENVIRONMENT_INITIALIZER, enableProdMode, importProvidersFrom, inject } from '@angular/core';
import { ReaderWebSharedProviders } from '@awread/reader/web/shared';
import { environment } from '@awread/global/environments';
import { ServiceWorkerModule } from '@angular/service-worker';
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { UniversalService } from '@awread/kernel/services';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';

if (environment.production) {
  enableProdMode();
}

export const mainProviders = [
  ...ReaderWebSharedProviders,
  provideHttpClient(withFetch()),
  { provide: APP_ID, useValue: 'serverApp' },
  provideClientHydration(
    withHttpTransferCacheOptions({
      // includePostRequests: true
    })
  ),
  importProvidersFrom(
    ServiceWorkerModule.register('ngsw-worker.js', {
      // ServiceWorkerModule.register('ngsw-worker-wrapper.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 15 seconds (whichever comes first).
      registrationStrategy: 'registerImmediately',
    }),
  ),

  {
    provide: ENVIRONMENT_INITIALIZER,
    useFactory: initializeAppFactory,
    multi: true,
    deps: [UniversalService],
  },
];




function initializeAppFactory(universalService: UniversalService) {
  const document = inject(DOCUMENT);
  return () => {
    if (universalService.isBrowser()) {
      console.log('try to load theme on browser', document.URL)
      loadTheme();
      return;
    } else {
      // console.log('this is server bro!', document.URL, document.querySelector('meta[name="is-mobile"]')?.innerHTML);
      const isMobile = document.querySelector('meta[name="is-mobile"]')?.getAttribute('content');

      switch (isMobile) {
        case 'unknown':
          (globalThis as any)['isMobile'] = true;
          // console.warn('unknown device ?? tai sao ko update? => is mobile true');
          break;
        case 'desktop':
          (globalThis as any)['isMobile'] = false;
          console.warn('desktop device => isMobile false');
          break;
        case 'mobile':
          (globalThis as any)['isMobile'] = true;
          console.warn('mobile device => isMobile true');
          break;

        default:
          break;
      }

    }
  };
}

function loadTheme() {
  // On page load or when changing themes, best to add inline in `head` to avoid FOUC
  // if (localStorage.getItem('theme') === 'auto' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
  const themeValue = localStorage.getItem('data-theme');
  if (themeValue?.length) {
    document.documentElement.setAttribute("data-theme", themeValue);
  } else {
    // auto
    console.log('theme auto')
    const hours = new Date().getHours();
    const themeValue = hours >= 6 && hours < 18 ? 'light' : 'dark';
    document.documentElement.setAttribute("data-theme", themeValue);
  }
}

//  export function fixPageFlickering(document: Document, platformId: Object): () => void {
//    return () => {
//      // console.log('app root', APPROOT);
//      if (isPlatformBrowser(platformId)) {
//        const target = 'app-root';
//        const transitionStyles = Array.from<HTMLElement>(document.querySelectorAll('style[ng-transition]'));
//        const serverRoot = document.body.querySelector(target) as HTMLElement;
//        const clientRoot = serverRoot.cloneNode() as HTMLElement;
//        serverRoot.setAttribute('ng-non-bindable', '');
//        clientRoot.style.display = 'none';
//        document.body.insertBefore(clientRoot, serverRoot);
//        transitionStyles.forEach((element: HTMLElement) => {
//          element.removeAttribute('ng-transition');
//        });
//        fromEvent(window, 'load').subscribe(() => {
//          transitionStyles.forEach((el: HTMLElement) => el.remove());
//          clientRoot.style.display = 'block';
//          serverRoot.remove();
//        });
//      }
//    };
//  }

//  {
//       provide: APP_INITIALIZER,
//       useFactory: fixPageFlickering,
//       deps: [DOCUMENT, PLATFORM_ID],
//       multi: true,
//     },

// export function fixPageFlickering(document: Document, platformId: Object): () => void {
//   return () => {
//     if (isPlatformBrowser(platformId)) {
//       fromEvent(window, 'DOMContentLoaded').subscribe(() => {
//         console.log('fromEvent DOMContentLoaded');
//         const serverRoot = document.body.querySelector('app-root[platform-node="server"]') as HTMLElement;
//         if (serverRoot) {
//           serverRoot.style.display = 'block';
//           // try {
//           //   const browserRoot = document.body.querySelector('app-root[platform-node="browser"]') as HTMLElement;
//           //   browserRoot.remove();
//           // } catch (error) {
//           //   console.error('this should not happen', error);
//           // }
//         }
//       });
//       fromEvent(window, 'load').subscribe(() => {
//         console.log('fromEvent load');
//         const serverRoot = document.body.querySelector('app-root[platform-node="server"]') as HTMLElement;
//         if (serverRoot) {
//           // serverRoot.style.display = 'block';
//           try {
//             const browserRoot = document.body.querySelector('app-root[platform-node="browser"]') as HTMLElement;
//             browserRoot.remove();
//           } catch (error) {
//             console.error('this should not happen', error);
//           }
//         }
//       });
//     } else {
//       // try {
//       //   console.log('this is server', global.window);
//       // } catch (error) {
//       //   console.log("server?")
//       // }
//     }
//   };
// }

// try {
//   // run this cause error on server to prevent code running on server
//   console.log(global.window);
// } catch (error) {
//   console.warn('browser?');
//   const serverRoot = document.body.querySelector('app-root') as HTMLElement;
//   if (serverRoot.innerHTML.includes('ngx-dynamic-hook')) {
//     serverRoot.style.display = 'none';
//     const clientRoot = serverRoot.cloneNode(true) as HTMLElement;
//     serverRoot.setAttribute('platform-node', 'browser');
//     clientRoot.style.display = 'none';
//     document.body.insertBefore(clientRoot, serverRoot);
//   }
// }

// platformBrowserDynamic()
//   .bootstrapModule(AppModule)
//   .catch((err) => console.error(err));
