import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastService } from './toast.service.js';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faXmark, faCheckCircle, faAnchorCircleExclamation, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './toast.component.html',
  styles: [
    `
      :host .z-1060 {
        z-index: 1060;
      }
    `,
  ],
})
export class ToastComponent implements OnInit {
  showToast = false;
  toastMessage = '';
  icons = { faXmark, faCheckCircle, faAnchorCircleExclamation, faCircleXmark };

  toastConfig = {
    isBtnClose: false,
    autoClose: true,
    duration: 2000,
    typeToast: 'success',
    position: 'bottom-right',
  };
  cd = inject(ChangeDetectorRef);

  constructor(public toastService: ToastService) { }

  ngOnInit() {
    this.showToast = true;
    this.toastService.status.subscribe((data: { message: string; typeToast: string; options: any }) => {
      if (data?.message) {
        const options = data?.options ?? {};
        this.showToast = true;
        this.toastConfig = { ...this.toastConfig, typeToast: data.typeToast, ...options };
        // console.log('duration', this.toastConfig.duration, data.options);
        this.toastMessage = data.message;
        this.cd.detectChanges();

        setTimeout(() => {
          this.showToast = false;
          this.cd.detectChanges();
        }, this.toastConfig.duration);
      }
    });
  }

  closeToast() {
    this.showToast = false;
  }
}
